import React from "react";
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'


const Header = () => {

    return (
        <Container>
            <Row>
                <Col className="text-center brand" xs='12' sm='12' md='12' lg='12' xl='12' xxl='12'>
                    <span>
                        <FontAwesomeIcon icon={faInfoCircle} className='icon' /> <strong>{window.location.hostname}</strong>  alan adı rezerve edilmiş durumdadır.
                    </span>
                </Col>
                
            </Row>
        </Container>
    )
};
export default Header;