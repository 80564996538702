import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
    return (
        <Container fluid>
            <Row className='align-items-center'>
                <Col>
                    <p className='text-center'>
                        <a href='https://www.sonicguvenlik.com.tr' target='blank'>Support</a> <br/><br/>
                        Copyright © 2011-2022 Sonic Bilgi Teknolojileri, All rights reserved.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}
export default Footer