import { Container, Row, Col } from 'react-bootstrap';
import './assets/css/custom.css';
import Header from './components/Header';
import Footer from './components/Footer';
function App() {
  return (
    <Container fluid className='vh-100'>
      <Row className="header"> 
        <Col>
        
          <Header />
        </Col>
      </Row>
      <Row>
        <Col className='footer'>
          <Footer/>
        </Col>
      </Row>
    </Container>


  );
}

export default App;
